<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <v-select
            v-model="SelectedDSR"
            :options="UserAllowedDSROptions"
            label="name"
            style="minWidth:250px"
            class="mb-1"
            placeholder="Select the Options"
          />
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="ViewReport()"
          ><font-awesome-icon
             :icon="['fas', 'eye']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">View Report</span></b-button>&nbsp;
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="clearFilter()"
          ><font-awesome-icon
             :icon="['fas', 'filter']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Clear</span></b-button>
        </div>
      </b-button-toolbar>

      <AgGridNoEdits :aggrid="aggrid" />
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  components: {
    AgGridNoEdits,
  },
  data() {
    return {
      SelectedDSR: '',
      UserAllowedDSROptions: [],
      aggrid: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('DSRReport')
    this.aggrid.columnDefs = [
      {
        headerName: 'Status', field: 'status', minWidth: 100, filter: 'agSetColumnFilter',
      },
      { headerName: 'Booking #', field: 'bookingNumber', minWidth: 100 },
      {
        headerName: 'Booking Date',
        field: 'receivedDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Booking Approval',
        field: 'bApprovedDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Cargo Ready',
        field: 'cargoReadyDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      { headerName: 'Commodity', field: 'commodity', minWidth: 100 },
      { headerName: 'PO', field: 'poNumber', minWidth: 100 },
      { headerName: 'Booking CBM', field: 'bcbm', minWidth: 100 },
      { headerName: 'Booking Weight', field: 'bWeight', minWidth: 100 },
      { headerName: 'Booking Pallet', field: 'bPallet', minWidth: 100 },
      { headerName: 'HBL', field: 'hbl', minWidth: 125 },
      { headerName: 'MBL', field: 'mbl', minWidth: 125 },
      {
        headerName: 'Shipment Approval',
        field: 'sApprovedDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      { headerName: 'Consignee', field: 'consignee', minWidth: 100 },
      { headerName: 'Shipper', field: 'seller', minWidth: 100 },
      { headerName: 'Shipment CBM', field: 'scbm', minWidth: 100 },
      { headerName: 'Shipment Weight', field: 'sWeight', minWidth: 100 },
      { headerName: 'Shipment Pallet', field: 'sPallet', minWidth: 100 },
      {
        headerName: 'Container #',
        field: 'containerNumber',
        minWidth: 130,
        // eslint-disable-next-line no-nested-ternary
        cellRenderer: params => (!this.CheckNull(params.value) ? params.data.bContainerSize === 'LCL' ? 'LCL' : `${params.data.bQty} x ${params.data.bContainerSize}` : params.value),
      },
      { headerName: 'Container Size', field: 'containerSize', minWidth: 100 },
      { headerName: 'POL', field: 'pol', minWidth: 100 },
      { headerName: 'POD', field: 'pod', minWidth: 100 },
      { headerName: 'Final Dest', field: 'finalDest', minWidth: 100 },
      { headerName: 'Carrier', field: 'carrier', minWidth: 100 },
      { headerName: 'Vessel Name', field: 'motherVesselName', minWidth: 130 },
      {
        headerName: 'Vessel ETD',
        field: 'motherETD',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Vessel ATD',
        field: 'motherATD',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      { headerName: 'ETD', field: 'bookingETD', minWidth: 100 },
      {
        headerName: 'ETA to First Port',
        field: 'etaFirstPort',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATA to First Port',
        field: 'ataFirstPort',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ETA to Final Dest.',
        field: 'etaFinalDest',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'ATA to Final Dest.',
        field: 'ataFinalDest',
        minWidth: 125,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      { headerName: 'Terminal', field: 'terminalLocation', minWidth: 100 },

      { headerName: 'Per diem Free Time', field: 'perdiemFreeTime', minWidth: 100 },
      {
        headerName: 'Last Free',
        field: 'lastFreeDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Empty Dispatch',
        field: 'dispatchDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Gate In',
        field: 'inGateDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Gate Out',
        field: 'outGateDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Discharged',
        field: 'dischargedDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Appointment',
        field: 'appointmentDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Delivered',
        field: 'deliveredDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Empty Return',
        field: 'emptyReturnDate',
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: this.$filterParams,
      },

    ]
    this.aggrid.rowData = []
  },
  mounted() {
    Promise.all([this.LoadingStart(), this.SetUserAllowedDSROptions()])
      .then(() => {
        this.LoadingEnd()
      })
  },
  methods: {
    SetUserAllowedDSROptions() {
      // Get user permission
      this.UserAllowedDSROptions = this.GetUserPermission('Booking').DSRReportOptions
    },
    LoadDSR(CustomerId) {
      axios.get(`/booking/reports/${CustomerId}/dsr`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.ResetColumns()
        })
    },
    ViewReport() {
      this.LoadDSR(this.SelectedDSR.id)
    },
  },
}
</script>
